<template>
    <modal ref="modalCrearDriver" titulo="Crear Driver" tamano="modal-lg" :cargado-modal="cargando" no-aceptar adicional="Crear" @adicional="crearDriver">
        <ValidationObserver ref="validacion">
            <div class="row mx-0 mb-4 justify-center">
                <div class="col-auto">
                    <slim-cropper
                    ref="foto"
                    :options="slimOptions"
                    class="border cr-pointer"
                    style="height:162px;width:162px;background-color:#F5F5F5;border-radius:6px;"
                    />
                </div>
            </div>
            <div class="row mx-0 justify-center mb-4 mt-2">
                <div class="col-5">
                    <ValidationProvider v-slot="{errors}" vid="nombre" rules="required" name="nombre">
                        <p class="text-general pl-3 f-14">Nombre</p>
                        <el-input v-model="model.nombre" placeholder="Nombre del Driver" class="w-100" />
                        <span class="text-danger w-100 f-11"> {{ errors[0] }} </span>
                    </ValidationProvider>
                    <ValidationProvider v-slot="{errors}" vid="identificacion" rules="required|numeric" name="documento de identificación">
                        <p class="text-general pl-3 f-14 mt-4">Documento de identificación</p>
                        <el-input v-model="model.identificacion" placeholder="Documento de identificación" class="w-100" />
                        <span class="text-danger w-100 f-11"> {{ errors[0] }} </span>
                    </ValidationProvider>
                </div>
                <div class="col-5">
                    <ValidationProvider v-slot="{errors}" vid="telefono" rules="required|numeric" name="teléfono">
                        <p class="text-general pl-3 f-14">Teléfono</p>
                        <el-input v-model="model.telefono" placeholder="Teléfono" class="w-100" />
                        <span class="text-danger w-100 f-11"> {{ errors[0] }} </span>
                    </ValidationProvider>
                    <ValidationProvider v-slot="{errors}" vid="cargamos_id" rules="required|numeric" name="id cargamos">
                        <p class="text-general pl-3 f-14 mt-4">Id cargamos</p>
                        <el-input v-model="model.cargamos_id" placeholder="id" class="w-100" />
                        <span class="text-danger w-100 f-11"> {{ errors[0] }} </span>
                    </ValidationProvider>
                </div>
            </div>
        </ValidationObserver>
    </modal>
</template>

<script>
import Drivers from "~/services/routing/drivers";
export default {
    data(){
        return {
            slimOptions: {
                ratio: '1:1',
                label:'Subir imagen',
                buttonCancelLabel: 'Cancelar',
                buttonConfirmLabel: 'ok',
                buttonUploadLabel: 'Ok',
            },
            model: {
                nombre: null,
                telefono: null,
                identificacion: null,
                cargamos_id: null
            },
            cargando: false
        }
    },
    methods: {
        toggle(){
            this.$refs.modalCrearDriver.toggle();
        },
        async crearDriver(){
            try {
                const valid = await this.$refs.validacion.validate()
                if(!valid){
                    this.notificacion('Mensaje', 'campos obligatorios', 'warning')
                    return false
                }
                const archivo = this.$refs.foto.instanciaCrop.dataBase64.output
                this.model.foto = archivo.image
                this.cargando = true

                const { data } = await Drivers.crearDriver(this.model)
                this.notificacion('Mensaje', data.message, 'success')
                this.limpiar()
                this.$refs.modalCrearDriver.toggle();
                this.$router.push({name: 'admin.rutas.ver-driver', params: {id_driver: data.model.id}})
            } catch (error){
                this.error_catch(error, 'validacion')
            }finally{
                this.cargando = false
            }
        },
        limpiar(){
            this.model = {
                nombre: null,
                telefono: null,
                identificacion: null,
                cargamos_id: null
            }
            this.$refs.validacion.reset()
            this.$refs.foto.instanciaCrop.remove()

        }
    }
}
</script>
