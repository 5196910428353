var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('modal',{ref:"modalCrearDriver",attrs:{"titulo":"Crear Driver","tamano":"modal-lg","cargado-modal":_vm.cargando,"no-aceptar":"","adicional":"Crear"},on:{"adicional":_vm.crearDriver}},[_c('ValidationObserver',{ref:"validacion"},[_c('div',{staticClass:"row mx-0 mb-4 justify-center"},[_c('div',{staticClass:"col-auto"},[_c('slim-cropper',{ref:"foto",staticClass:"border cr-pointer",staticStyle:{"height":"162px","width":"162px","background-color":"#F5F5F5","border-radius":"6px"},attrs:{"options":_vm.slimOptions}})],1)]),_c('div',{staticClass:"row mx-0 justify-center mb-4 mt-2"},[_c('div',{staticClass:"col-5"},[_c('ValidationProvider',{attrs:{"vid":"nombre","rules":"required","name":"nombre"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('p',{staticClass:"text-general pl-3 f-14"},[_vm._v("Nombre")]),_c('el-input',{staticClass:"w-100",attrs:{"placeholder":"Nombre del Driver"},model:{value:(_vm.model.nombre),callback:function ($$v) {_vm.$set(_vm.model, "nombre", $$v)},expression:"model.nombre"}}),_c('span',{staticClass:"text-danger w-100 f-11"},[_vm._v(" "+_vm._s(errors[0])+" ")])]}}])}),_c('ValidationProvider',{attrs:{"vid":"identificacion","rules":"required|numeric","name":"documento de identificación"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('p',{staticClass:"text-general pl-3 f-14 mt-4"},[_vm._v("Documento de identificación")]),_c('el-input',{staticClass:"w-100",attrs:{"placeholder":"Documento de identificación"},model:{value:(_vm.model.identificacion),callback:function ($$v) {_vm.$set(_vm.model, "identificacion", $$v)},expression:"model.identificacion"}}),_c('span',{staticClass:"text-danger w-100 f-11"},[_vm._v(" "+_vm._s(errors[0])+" ")])]}}])})],1),_c('div',{staticClass:"col-5"},[_c('ValidationProvider',{attrs:{"vid":"telefono","rules":"required|numeric","name":"teléfono"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('p',{staticClass:"text-general pl-3 f-14"},[_vm._v("Teléfono")]),_c('el-input',{staticClass:"w-100",attrs:{"placeholder":"Teléfono"},model:{value:(_vm.model.telefono),callback:function ($$v) {_vm.$set(_vm.model, "telefono", $$v)},expression:"model.telefono"}}),_c('span',{staticClass:"text-danger w-100 f-11"},[_vm._v(" "+_vm._s(errors[0])+" ")])]}}])}),_c('ValidationProvider',{attrs:{"vid":"cargamos_id","rules":"required|numeric","name":"id cargamos"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('p',{staticClass:"text-general pl-3 f-14 mt-4"},[_vm._v("Id cargamos")]),_c('el-input',{staticClass:"w-100",attrs:{"placeholder":"id"},model:{value:(_vm.model.cargamos_id),callback:function ($$v) {_vm.$set(_vm.model, "cargamos_id", $$v)},expression:"model.cargamos_id"}}),_c('span',{staticClass:"text-danger w-100 f-11"},[_vm._v(" "+_vm._s(errors[0])+" ")])]}}])})],1)])])],1)}
var staticRenderFns = []

export { render, staticRenderFns }